<template>
  <div id="chart" style="cursor: pointer">
    <apexchart
      v-if="!isLoading"
      type="bar"
      height="400"
      :options="barPriceOptions"
      :series="barPriceSeries"
    >
    </apexchart>
  </div>
</template>
<script>
export default {
  name: "BarPrice",
  props: {
    _series: {
      type: Array,
      default() {
        return [];
      },
    },
    _categories: {
      type: Array,
      default() {
        return [];
      },
    },
    _title: { type: String, default: "" },
    _yaxisText: { type: String, default: "" },
  },
  components: {},
  data() {
    return {
      isLoading: true,
      barPriceSeries: [],
      barPriceOptions: {
        chart: {
          type: "bar",
          height: 250,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false, // Вимкнути панель інструментів
          },
        },
        title: {
          text: this._title,
        },
        label: [],
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          offsetY: 5,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: this._yaxisText,
          },
        },
      },
    };
  },
  methods: {
    setOptions() {
      this.barPriceOptions = {
        ...this.barPriceOptions,
        ...{
          xaxis: {
            categories: this._categories,
          },
        },
      };
      this.barPriceSeries = this._series;
      this.isLoading = false;
    },
  },
  created() {},
  mounted() {
    this.setOptions();
  },
  watch: {
    _series() {
      this.setOptions();
    },
    _title() {
      this.barPriceOptions = {
        ...this.barPriceOptions,
        ...{
          title: {
            text: this._title,
          },
        },
      };
    },
    _yaxisText() {
      this.barPriceOptions = {
        ...this.barPriceOptions,
        ...{
          yaxis: {
            title: {
              text: this._yaxisText,
            },
          },
        },
      };
    },
  },
};
</script>
<style lang="scss"></style>
